import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "./Layout";

export const query = graphql
    `query EventByID(
        $slug: String!,
    ) {

        mdx(slug: {eq: $slug}) {
            body
            slug
            frontmatter {
                title
                speaker
                isCancelled
                isoYear: start(formatString: "YYYY")
                isoStartDate: start(formatString: "MMMM Do")
                isoStartTime: start(formatString: "h:mmA")
                isoEndTime: end(formatString: "h:mmA")
                locationName
                locationCoords
            }
        }
    }
`

const Event = ({ data, location }) => {

    const { body, frontmatter } = data.mdx;

    return (
        <Layout
            location={location}
            title={`${frontmatter.isCancelled !== null && frontmatter.isCancelled === true ? "CANCELLED: " : ""}${frontmatter.title}`}
            isEvent
        >
            <div>
                <div>
                    <div>
                    {frontmatter.speaker && frontmatter.speaker.length > 0 &&
                        <p>with {frontmatter.speaker}</p>
                    }

                    {frontmatter.isoStartDate && frontmatter.isoYear &&
                        <p>{frontmatter.isoStartDate}, {frontmatter.isoYear}</p>
                    }
                    
                    {frontmatter.isoStartTime && frontmatter.isoEndTime &&
                        <p>{frontmatter.isoStartTime} - {frontmatter.isoEndTime}</p>
                    }
                    
                    {frontmatter.locationName !== null && frontmatter.locationName.length > 0 &&
                        <p>{frontmatter.locationName}</p>
                    }
                        <MDXRenderer>{body}</MDXRenderer>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Event;